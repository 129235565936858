import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '.';
import { Pagination } from '../models';
import { ProductModel } from '../models/product.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';
import { IProductService } from './iterfaces/product.interface';

@Injectable({
  providedIn: 'root'
})
export class ProductProfileService implements IProductService {

  backendUrl: string;

  constructor(
    public appConfigService: AppConfigService,
    public http: HttpClient,
    private paginatorGenerator: PaginationGeneratorService
  ) {
    this.backendUrl = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/sp`;
  }

  getProductsBySp(queryParams: Pagination): Observable<{ items: ProductModel[], paged: any }> {
    const params = this.paginatorGenerator.generatePagination(queryParams);
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/profile/product`, { params });
  }

  getProductById(servId: string): Observable<ProductModel> {
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/profile/product/${servId}`);
  }

  createOrUpdateProduct(data: any): Observable<ProductModel> {
    if (!data.id) {
      return this.createService(data);
    }
    return this.updateService(data.id, data);
  }

  createService(data: any): Observable<ProductModel> {
    return this.http.post<any>(`${this.backendUrl}/serviceprovider/profile/product`, data);
  }

  updateService(servId: string, data: any): Observable<ProductModel> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/profile/product/${servId}`, data);
  }

  disableEnableProduct(pp: ProductModel): Observable<any> {
    if (!pp.isDeleted) {
      return this.disableProduct(pp);
    } else {
      return this.activateProduct(pp);
    }
  }


  disableProduct(serv: ProductModel): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/serviceprovider/profile/product/${serv.id}`);
  }

  activateProduct(serv: ProductModel): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/profile/product/${serv.id}/activate`, { id: serv.id });
  }
}
