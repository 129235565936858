import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '.';
import { Pagination } from '../models';
import { PickupPointsModel } from '../models/pickupPoints.model';
import { PaginationGeneratorService } from '../util/pagination-generator.service';
import { IPickupPoints } from './iterfaces/pickup-points.interface';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class PickupPointsProfileService implements IPickupPoints {

  private backendUrl: string;
  paginatorGenerator = new PaginationGeneratorService();

  constructor(
    public appConfigService: AppConfigService,
    public http: HttpClient,
    public userService: UserService
  ) {
    this.backendUrl = `${this.appConfigService.appConfig.SERVER_URL}${this.appConfigService.appConfig.API_STRING}/sp`;
  }

  getPickupPointsBySp(queryParams?: Pagination): Observable<{ items: PickupPointsModel[], paged: any }> {
    let params;
    if (queryParams) {
      params = this.paginatorGenerator.generatePagination(queryParams);
    };
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/profile/pickup-point`, { params });
  }

  getPickupPointsById(pPId: string): Observable<PickupPointsModel> {
    return this.http.get<any>(`${this.backendUrl}/serviceprovider/profile/pickup-point/${pPId}`);
  }

  createOrUpdatePickUpPoint(data: any): Observable<PickupPointsModel> {
    if (!data.id) {
      return this.createPickupPoint(data);
    }
    return this.updatePickupPoint(data.id, data);
  }

  createPickupPoint(data: any): Observable<PickupPointsModel> {
    return this.http.post<any>(`${this.backendUrl}/serviceprovider/profile/pickup-point`, data);
  }

  updatePickupPoint(pPId: string, data: any): Observable<PickupPointsModel> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/profile/pickup-point/${pPId}`, data);
  }

  disableEnablePickupPoint(pp: PickupPointsModel): Observable<any> {
    if (!pp.isDeleted) {
      return this.disablePickupPoint(pp);
    } else {
      return this.activatePickupPoint(pp);
    }
  }


  disablePickupPoint(pp: PickupPointsModel): Observable<any> {
    return this.http.delete<any>(`${this.backendUrl}/serviceprovider/profile/pickup-point/${pp.id}`);
  }

  activatePickupPoint(pp: PickupPointsModel): Observable<any> {
    return this.http.patch<any>(`${this.backendUrl}/serviceprovider/profile/pickup-point/${pp.id}/activate`, { id: pp.id });
  }
}
